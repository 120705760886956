import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Img from 'gatsby-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SocialMediaLinks } from '../../utils'
import { OutboundLink } from 'gatsby-plugin-google-gtag'


const PageBuyNow = ({ pageContext, location }) => {
    const page = pageContext.page

    const data = useStaticQuery(graphql`
    query {
        imageMobile: file(relativePath: {eq: "buynow-header-mobile.png"}) {
            id
            childImageSharp {
                fluid (maxWidth: 460) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageDesktop: file(relativePath: {eq: "buynow-header.png"}) {
            id
            childImageSharp {
                fluid (maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        shopee: file(relativePath: {eq: "shopee.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 540
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        lazada: file(relativePath: {eq: "lazada.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 540
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        watsons: file(relativePath: {eq: "watsons.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 540
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }

        favicon: file(relativePath: {eq: "favicon-w200.png"}) {
            id
            childImageSharp {
                original {
                    src
                }
            }
        }
    }
    `)

    const sources = [
        data.imageMobile.childImageSharp.fluid,
        {
            ...data.imageDesktop.childImageSharp.fluid,
            media: `(min-width: 600px)`,
        }
    ]
    
    // const imgStyles = {
    //     maxWidth: document.querySelector('body').offsetWidth < 768 ? '460px' : '900px',
    //     minWidth: document.querySelector('body').offsetWidth < 768 ? '100%' : '',
    // }

    const itmStyle = {
        width: '420px', 
        minWidth: '260px',
        marginTop: '1rem',
        marginLeft: '5px', marginRight: '5px',
        cursor: 'pointer'
    }
    
    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <h1 className="mx-5 text-center mt-7 md:mb-5">
                <Img fluid={sources} className={`mx-auto`} style={{maxWidth:'800px'}} alt={`Premium quality condoms delivered to your door discreetly!`}/>
            </h1>
            
            <div className="px-4 mx-auto my-container">
                
                <div className="flex flex-wrap items-center justify-center">

                    <OutboundLink style={itmStyle} href="https://shopee.ph/trust.premiere.ez" target="_blank" rel="noreferrer">
                        <GatsbyImage image={data.shopee.childImageSharp.gatsbyImageData} style={{width:'100%'}} alt="Shopee"/>
                    </OutboundLink>

                    <OutboundLink style={itmStyle} href="https://www.lazada.com.ph/premiere/?from=input&q=premiere%20condoms" target="_blank" rel="noreferrer">
                        <GatsbyImage image={data.lazada.childImageSharp.gatsbyImageData} style={{width:'100%'}} alt="Lazada"/>
                    </OutboundLink>
                    
                    <OutboundLink style={itmStyle} href="https://www.watsons.com.ph/health-and-rx/condoms/c/060401?q=:igcBestSeller:masterBrandName:Trust&text=trust&minSel=20.0&maxSel=149.0&minSlider=20.0&maxSlider=149.0&resultsForPage=30&sort=" target="_blank" rel="noreferrer">
                        <GatsbyImage image={data.watsons.childImageSharp.gatsbyImageData} style={{width:'100%'}} alt="Watson"/>
                    </OutboundLink>

                </div>

            </div>
        </Layout>
    )
}

export default PageBuyNow